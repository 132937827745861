<template>
  <div>
    <p-header v-if="showHeader" />
    <slot />
    <p-footer v-if="showFooter" />
  </div>
</template>

<script lang="ts">
import PHeader from './PHeader/index.vue'
import PFooter from './PFooter/index.vue'

export default {
  components: {
    PHeader,
    PFooter,
  },

  setup() {
    const route = useRoute()
    const { $device } = useNuxtApp()

    const showFooter = computed(() => {
      return route.path === '/' || route.name === 'index'
    })

    const showHeader = computed(() => {
      const isMarketDetailPage = route.name === 'markets-marketName'

      return !isMarketDetailPage || !$device.isMobile
    })

    return {
      showFooter,
      showHeader,
    }
  },
}
</script>
